export function caseInsensitveAlphabeticalSort(a: string, b: string) {
    return a.toLowerCase() > b.toLowerCase() ? 1 : b.toLowerCase() > a.toLowerCase() ? -1 : 0;
}

export function isValidMp3File(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
        // Check if file is provided and has a .mp3 extension
        if (!file || !file.name.endsWith(".mp3")) {
            return resolve(false);
        }

        // Check MIME type as a preliminary test
        if (file.type !== "audio/mpeg") {
            return resolve(false);
        }

        // Read the first few bytes to confirm MP3 format
        const reader = new FileReader();
        reader.onload = function (event: ProgressEvent<FileReader>) {
            const buffer = new Uint8Array(event?.target?.result as ArrayBuffer);

            // Check for MP3 header signature
            const isMp3Header =
                (buffer[0] === 0xff && (buffer[1] & 0xe0) === 0xe0) ||
                (buffer[0] === 0x49 && buffer[1] === 0x44 && buffer[2] === 0x33);

            resolve(isMp3Header);
        };

        reader.onerror = function () {
            reject(new Error("File could not be read"));
        };

        // Read the first 10 bytes to check for the MP3 signature
        reader.readAsArrayBuffer(file.slice(0, 10));
    });
}
