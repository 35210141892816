import { parseJSONHandlebarsTemplate } from "@regal-voice/shared-parsers";

import { isValidMp3File } from "Components/FormItems/shared";
import { isValidLink } from "Services/HelpersService";
import { isStringValidRegalDateFormat } from "Services/TimeService";

import type { RuleValidationFn } from "../types";
import type { RcFile, UploadFile } from "antd/es/upload";

type GenericInputRule = {
    required?: boolean;
    whitespace?: boolean;
    message: string;
};

export const generateFormItemRequiredRules = (inputRequired: string, noMessage = false, assertWhiteSpace = true) => {
    const rules: GenericInputRule[] = [
        {
            required: true,
            message: noMessage ? "" : `${inputRequired} is required.`,
        },
    ];

    if (assertWhiteSpace) {
        rules.push({
            whitespace: true,
            message: noMessage ? "" : `${inputRequired} cannot be blank spaces only.`,
        });
    }

    return rules;
};

// TODO: conlidate with OR define usecase for this vs generatePhoneNumberValidator
export const generateFormItemPhoneNumberRule = (label = "Phone Number") => {
    return {
        async validator(_: unknown, value: string) {
            if (!/^1?\d{10}$/.test(value?.replace(/[^\d]/g, "") || "")) {
                throw `${label} must be valid 10 digit number.`;
            }
        },
    };
};

// TODO: conlidate with OR define usecase for this vs generateFormItemPhoneNumberRules
export const generatePhoneNumberValidator = (ErrorDisplay: JSX.Element) => {
    return {
        async validator(_: any, value: string) {
            if (!value || value.match(/^\+/)) {
                return Promise.resolve();
            }
            throw ErrorDisplay;
        },
    };
};

export const generateExistanceValidator = (message: string) => {
    return {
        async validator(_: any, value?: string) {
            if (value === undefined || value === null || value === "") {
                return Promise.reject(message);
            }
            return Promise.resolve();
        },
    };
};

export function generateContainsWhitespaceValidator(message: string): RuleValidationFn {
    return (rule: unknown, value?: string) => {
        return value?.includes(" ") ? Promise.reject(message) : Promise.resolve();
    };
}

export function generateDisallowSelectionValidator(disallow: Array<string>, message: string): RuleValidationFn {
    return (rule: unknown, value: string) => {
        const disallowMessage = `${value} ${message}`;
        return disallow.includes(value) ? Promise.reject(disallowMessage) : Promise.resolve();
    };
}

// IF you are using this. you should update your form to use a Form.List be using the isDuplicateListValue from ListValidators
export function generateDuplicateOptionValidator(message: string, options: Array<string>): RuleValidationFn {
    return (rule: unknown, value: string) => {
        const isDupe = value !== "" && options.includes(value);
        return isDupe ? Promise.reject(message) : Promise.resolve();
    };
}

export function generateDisallowedListValidator(disallowedList: Array<string>, message: string): RuleValidationFn {
    return (_: any, value: string) => {
        const isDisallowed = disallowedList.includes(value);
        if (isDisallowed) {
            return Promise.reject(message);
        }
        return Promise.resolve();
    };
}

export function generateDateTimeValidator(message: string) {
    return {
        validator(_: unknown, value: string) {
            if (isStringValidRegalDateFormat(value)) {
                return Promise.resolve();
            }
            return Promise.reject(message);
        },
    };
}

export function generateJsonValidator(message: string) {
    return (rule: unknown, value: string) => {
        try {
            const parts = parseJSONHandlebarsTemplate(value);
            if (parts?.status == "error") {
                return Promise.reject(message);
            }
            return Promise.resolve();
        } catch {
            return Promise.reject(message);
        }
    };
}

export function generateUrlValidator(message: string) {
    return (rule: unknown, value: string) => {
        try {
            new URL(value);
        } catch {
            return Promise.reject(message);
        }
        return Promise.resolve();
    };
}

async function audioSelectionAndDurationValidation(allowedVoicemailDuration: number, fileObj?: RcFile) {
    const audioCtx = new window.AudioContext();
    if (fileObj) {
        if (!(await isValidMp3File(fileObj))) {
            return Promise.reject(`Audio file format cannot be accepted. See above link for more guidance.`);
        }
        const buffer = await audioCtx.decodeAudioData(await fileObj?.arrayBuffer());
        if (buffer.duration > allowedVoicemailDuration) {
            return Promise.reject(`Please select an audio file under ${allowedVoicemailDuration} seconds long.`);
        }
    } else {
        return Promise.reject(`Please select an audio file.`);
    }
    return Promise.resolve();
}

export function generateAudionSelectionAndDurationValidator(duration: number) {
    return {
        validator(_: unknown, value: UploadFile<any>) {
            return audioSelectionAndDurationValidation(duration, value?.originFileObj);
        },
    };
}

// Alternative custom validator for URLs. Seems to work better than the one above which allows some weird URLs.
export function generateLinkValidator(message: string) {
    return {
        validator(_: unknown, value: string) {
            if (!value || isValidLink(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(message);
            }
        },
    };
}

export function generateIntegerValidator() {
    return {
        validator(_: unknown, value: string | number) {
            if (Number.isInteger(Number(value))) {
                return Promise.resolve();
            }
            return Promise.reject("No decimals allowed.");
        },
    };
}

export function generateMaxValueValidator(fieldName: string, max: number, errorMessage?: string) {
    errorMessage = errorMessage || `${fieldName} cannot exceed ${max}.`;
    return {
        validator(_: unknown, value: number) {
            if (value > max) {
                return Promise.reject(errorMessage);
            }
            return Promise.resolve();
        },
    };
}

export function generateMinValueValidator(fieldName: string, min: number, errorMessage?: string) {
    errorMessage = errorMessage || `${fieldName} cannot be less than ${min}.`;
    return {
        validator(_: unknown, value: number) {
            if (value < min) {
                return Promise.reject(errorMessage);
            }
            return Promise.resolve();
        },
    };
}

export function generateNonEmptyListValidator(error?: string) {
    return {
        validator: (_: any, values: Array<string> | undefined) => {
            if (!values?.length) {
                return Promise.reject(error ?? "Please select at least one value.");
            }
            return Promise.resolve();
        },
    };
}
